import { Injectable, signal } from '@angular/core';
import {
  UserInterface,
  UserLoginInterface,
  UserResponseInterface,
} from '../types/user.interface';
import { Observable, of, tap } from 'rxjs';
import { SnapshotService } from '../services/snapshot.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthApiService {
  currentUser = signal<UserInterface | null>(null);

  constructor(
    private snapshotService: SnapshotService,
    private router: Router,
  ) {}

  login(data: UserLoginInterface): Observable<UserResponseInterface> {
    const fakeResponse = this.createFakeResponse(data);
    return of(fakeResponse).pipe(
      tap((response) => this.handleAuthResponse(response, 'login')),
    );
  }

  register(data: UserLoginInterface): Observable<UserResponseInterface> {
    const fakeResponse = this.createFakeResponse(data);
    return of(fakeResponse).pipe(
      tap((response) => this.handleAuthResponse(response, 'register')),
    );
  }

  private handleAuthResponse(
    response: UserResponseInterface,
    action: 'login' | 'register',
  ): void {
    this.setAuthFromLocalStorage(response.accessToken);
    this.snapshotService.isAuth.set(true);
    this.currentUser.set(response.userInfo);
    this.snapshotService.loadAuthModule().then((authModule) => {
      this.router.resetConfig([
        {
          path: '',
          loadChildren: () => Promise.resolve(authModule),
        },
      ]);
      if (action === 'register') {
        this.router.navigate(['/create-account']).then();
      } else {
        this.router.navigate(['/']).then();
      }
    });
  }

  private createFakeResponse(data: UserLoginInterface): UserResponseInterface {
    return {
      accessToken: 'fake-jwt-token',
      userInfo: {
        id: '123',
        username: data.email.split('@')[0],
        email: data.email,
      },
    };
  }

  getCurrentUser(): Observable<UserInterface> {
    const fakeUser: UserInterface = {
      id: '123',
      username: 'fakeUser',
      email: 'fakeUser@example.com',
    };
    return of(fakeUser);
  }

  logout(): void {
    this.snapshotService.isAuth.set(false);
    this.currentUser.set(null);
    localStorage.removeItem('token');
    this.snapshotService.loadNotAuthModule().then((NotAuthModule) => {
      this.router.resetConfig([
        {
          path: '',
          loadChildren: () => Promise.resolve(NotAuthModule),
        },
      ]);
      this.router.navigateByUrl('/');
    });
  }

  setAuthFromLocalStorage(token: string): boolean {
    if (token) {
      localStorage.setItem('token', token);
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): string | undefined {
    try {
      return localStorage.getItem('token') as string;
    } catch (error) {
      return undefined;
    }
  }
}
