import { Injectable, signal } from '@angular/core';

@Injectable()
export class SnapshotService {
  isAuth = signal<boolean>(false);

  loadAuthModule = () =>
    import('../pages/auth/auth.module').then((module) => {
      return module.AuthModule;
    });

  loadNotAuthModule = () =>
    import('../pages/not-auth/not-auth.module').then((module) => {
      return module.NotAuthModule;
    });
}
